import React, {useContext, Suspense, useState,useEffect } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "./catalogue.css";
import { DataCatalogueContext } from "../../globalContext";
import RightArrowImage from "../../assets/right-arrow.png";
import AreaChartIcon from "../../assets/area-graph.png";
import PieChartIcon from "../../assets/pi-chart.png";
import DoughnutChartIcon from "../../assets/doughnut-chart.png";
import AsiIcon from "../../assets/factory.png";
import HcesIcon from "../../assets/hces.png";
import MisIcon from "../../assets/mis.png";
import AllIcon from "../../assets/dataset.png";
import CamsIcon from "../../assets/cams-icon.png";
import BarChartIcon from "../../assets/bar-graph.png";
import LoadingSpinner from '../../Components/loader/spinner';
const Brand = React.lazy(() => import("../../Components/header/appheader"));
const Footer = React.lazy(()=> import('../../Components/footer/'))

export default function DataCatalogueMain() {
   const countData = useContext(DataCatalogueContext);
   
  
  return (
 <Suspense fallback={<div className='text-center loadspinner'><LoadingSpinner /></div>}>
      <Container className="udp-hp-wrapper" maxWidth="false">
        <Brand />
        <div className="hp-body">
          <Grid container spacing={5} className="section-1">
            <Grid item className="left-box" sm={12} md={12} lg={6}>
            <Typography variant="h2">DATA CATALOGUE</Typography>
                <p>
                A data catalog is a detailed inventory of all data assets in an organization, designed to help data professionals quickly find the most appropriate data for any analytical or business purpose.*
                <br /><br />
                It is a single point of access to all publicly available datasets of the ministry designed to make the datasets easy to find, download and share. At present it is a repository of about 2300 datasets.
                </p>
                
               <Link to="/catalogue-main/catalogue" title="Click for more detail">
                  <Button color="warning" variant="outlined" className="btn-primary">Find Datasets <img src={RightArrowImage} alt="right-arrow" className="btn-icon"/></Button>
							 </Link>
               <h6><i className="astrik">*</i>  <span>www.ibm.com/topics/data-catalog</span></h6>
					
            </Grid>
            <Grid item className="right-box anim-text image-wrap" sm={12} md={12} lg={6}>
													   
																  
            </Grid>
           
          </Grid>
          <Grid container spacing={5} className="section-2">
          <Grid item className="right-box anim-text" sm={12} md={12} lg={12}>
          <Grid container className="pods-container catalog-pods">
                 <Grid item className="pod1 pods tooltip1" sm={12} md={6} lg={3}>
                  <Link to={'/catalogue-main/catalogue?page=0&search=&product=CPI'} title="Click to View CPI Datasets">
                     <Grid container className="pods-wrap">
                     <Grid item sm={12} md={12} lg={12} className="col-h">
                           <h5>CONSUMER PRICE <br className="break"/>INDEX</h5>
                        </Grid>
                        <Grid item sm={6} md={6} lg={6} className="col-1">
                           <h2>CPI</h2>
                           <span className="cat-reference">&nbsp;</span>
                        </Grid>
                        <Grid item sm={6} md={6} lg={6} className="col-2">
                           <div className="pods-icon-wrap">
                              <img src={BarChartIcon} alt="bar chart icon"/>
                              <span className="tooltiptext1">{countData.cpiCount} <br className="break"/> Datasets</span>
                           </div>
                        </Grid>
                        
                     </Grid>
                     </Link>
                  </Grid>
                  <Grid item className="pod2 pods tooltip2" sm={12} md={6} lg={3}>
                  <Link to={'/catalogue-main/catalogue?page=0&search=&product=IIP'} title="Click to View IIP Datasets">
                     <Grid container className="pods-wrap">
                     <Grid item sm={12} md={12} lg={12} className="col-h">
                           <h5>INDEX OF INDUSTRIAL <br className="break"/>PRODUCTION</h5>
                        </Grid>
                        <Grid item sm={6} md={6} lg={6} className="col-1">
                           <h2>IIP</h2>
                           <span className="cat-reference">&nbsp;</span>
                        </Grid>
                        <Grid item sm={6} md={6} lg={6} className="col-2">
                           <div className="pods-icon-wrap">
                              <img src={AreaChartIcon} alt="bar chart icon"/>
                              <span className="tooltiptext2">{countData.iipCount} <br className="break"/> Datasets</span>
                           </div>
                        </Grid>
                     </Grid>
                     </Link>
                  </Grid>
                  <Grid item className="pod3 pods tooltip3" sm={12} md={6} lg={3}>
                  <Link to={'/catalogue-main/catalogue?page=0&search=&product=NAS'} title="Click to View NAS Datasets">
                     <Grid container className="pods-wrap">
                     <Grid item sm={12} md={12} lg={12} className="col-h">
                           <h5>NATIONAL ACCOUNTS <br className="break"/>STATISTICS</h5>
                        </Grid>
                        <Grid item sm={6} md={6} lg={6} className="col-1">
                           <h2>NAS</h2>
                           <span className="cat-reference">&nbsp;</span>
                        </Grid>
                        <Grid item sm={6} md={6} lg={6} className="col-2">
                           <div className="pods-icon-wrap">
                              <img src={PieChartIcon} alt="bar chart icon"/>
                              <span className="tooltiptext3">{countData.nasCount}<br className="break"/> Datasets</span>
                           </div>
                        </Grid>
                     </Grid>
                     </Link>
                  </Grid>
                  <Grid item className="pod4 pods tooltip4" sm={12} md={6} lg={3}>
                  <Link to={'/catalogue-main/catalogue?page=0&search=&product=PLFS'} title="Click to View PLFS Datasets">
                     <Grid container className="pods-wrap">
                     <Grid item sm={12} md={12} lg={12} className="col-h">
                           <h5>PERIODIC LABOUR <br className="break"/>FORCE SURVEY</h5>
                        </Grid>
                        <Grid item sm={6} md={6} lg={6} className="col-1">
                           <h2>PLFS</h2>
                           <span className="cat-reference">&nbsp;</span>
                        </Grid>
                        <Grid item sm={6} md={6} lg={6} className="col-2">
                           <div className="pods-icon-wrap">
                              <img src={DoughnutChartIcon} alt="bar chart icon"/>
                              <span className="tooltiptext4">{countData.plfsCount} <br className="break"/> Datasets</span>
                           </div>
                        </Grid>
                     </Grid>
                     </Link>
                  </Grid>
                  <Grid item className="pod4 pods tooltip4" sm={12} md={6} lg={3}>
                  <Link to={'/catalogue-main/catalogue?page=0&search=&product=ASI'} title="Click to View ASI Datasets">
                     <Grid container className="pods-wrap">
                     <Grid item sm={12} md={12} lg={12} className="col-h">
                           <h5>ANNUAL SURVEY OF <br className="break"/>INDUSTRIES</h5>
                        </Grid>
                        <Grid item sm={6} md={6} lg={6} className="col-1">
                           <h2>ASI</h2>
                           <span className="cat-reference">2021-22</span>
                        </Grid>
                        <Grid item sm={6} md={6} lg={6} className="col-2">
                           <div className="pods-icon-wrap">
                              <img src={AsiIcon} alt="asi chart icon"/>
                              <span className="tooltiptext4">{countData.asiCount} <br className="break"/> Datasets</span>
                           </div>
                        </Grid>
                     </Grid>
                     </Link>
                  </Grid>
                  <Grid item className="pod4 pods tooltip4" sm={12} md={6} lg={3}>
                  <Link to={'/catalogue-main/catalogue?page=0&product=HCES'} title="Click to View HCES Datasets">
                     <Grid container className="pods-wrap">
                     <Grid item sm={12} md={12} lg={12} className="col-h">
                           <h5>HOUSEHOLD CONSUMPTION <br className="break"/>EXPENDITURE SURVEY</h5>
                        </Grid>
                        <Grid item sm={6} md={6} lg={6} className="col-1">
                           <h2>HCES</h2>
                           <span className="cat-reference">2022-23</span>
                        </Grid>
                        <Grid item sm={6} md={6} lg={6} className="col-2">
                           <div className="pods-icon-wrap">
                              <img src={HcesIcon} alt="bar chart icon"/>
                              <span className="tooltiptext4"> {countData.hcesCount} <br className="break"/>Datasets</span>
                           </div>
                        </Grid>
                     </Grid>
                     </Link>
                  </Grid>
                  <Grid item className="pod4 pods tooltip4" sm={12} md={6} lg={3}>
                  <Link to={'/catalogue-main/catalogue?page=0&product=MIS'} title="Click to View MIS Datasets">
                     <Grid container className="pods-wrap">
                     <Grid item sm={12} md={12} lg={12} className="col-h">
                           <h5>MULTIPLE INDICATOR <br className="break"/>SURVEY</h5>
                        </Grid>
                        <Grid item sm={6} md={6} lg={6} className="col-1">
                           <h2>MIS</h2>
                           <span className="cat-reference">NSS 78th Round</span>
                        </Grid>
                        <Grid item sm={6} md={6} lg={6} className="col-2">
                           <div className="pods-icon-wrap">
                              <img src={MisIcon} alt="mis chart icon"/>
                              <span className="tooltiptext4">{countData.misCount} <br className="break"/> Datasets</span>
                           </div>
                        </Grid>
                     </Grid>
                     </Link>
                  </Grid>
                  <Grid item className="pod4 pods tooltip4" sm={12} md={6} lg={3}>
                  <Link to={'/catalogue-main/catalogue?page=0&product=ASUSE'} title="Click to View All Datasets">
                     <Grid container className="pods-wrap">
                     <Grid item sm={12} md={12} lg={12} className="col-h">
                           <h5>ANNUAL SURVEY OF UNINCORPORATED  <br className="break"/>SECTOR ENTERPRISES</h5>
                        </Grid>
                        <Grid item sm={6} md={6} lg={6} className="col-1">
                           <h2>ASUSE</h2>
                           <span className="cat-reference">&nbsp;</span>
                        </Grid>
                        <Grid item sm={6} md={6} lg={6} className="col-2">
                           <div className="pods-icon-wrap">
                              <img src={AllIcon} alt="dataset chart icon"/>
                              <span className="tooltiptext4">{countData.asuseCount} <br className="break"/> Datasets</span>
                           </div>
                        </Grid>
                     </Grid>
                     </Link>
                  </Grid>
                  <Grid item className="pod4 pods tooltip4" sm={12} md={6} lg={3}>
                  <Link to={'/catalogue-main/catalogue?page=0&product=CAMS'} title="Click to View All Datasets">
                     <Grid container className="pods-wrap">
                     <Grid item sm={12} md={12} lg={12} className="col-h">
                           <h5>COMPREHENSIVE ANNUAL MODULAR<br className="break"/>SURVEY</h5>
                        </Grid>
                        <Grid item sm={6} md={6} lg={6} className="col-1">
                           <h2>CAMS</h2>
                           <span className="cat-reference">&nbsp;</span>
                        </Grid>
                        <Grid item sm={6} md={6} lg={6} className="col-2">
                           <div className="pods-icon-wrap">
                              <img src={CamsIcon} alt="dataset chart icon"/>
                              <span className="tooltiptext4">{countData.camsCount} <br className="break"/> Datasets</span>
                           </div>
                        </Grid>
                     </Grid>
                     </Link>
                  </Grid>
                  <Grid item className="pod4 pods tooltip4" sm={12} md={6} lg={3}>
                  <Link to={'/catalogue-main/catalogue'} title="Click to View All Datasets">
                     <Grid container className="pods-wrap">
                     <Grid item sm={12} md={12} lg={12} className="col-h">
                           <h5>ALL  <br className="break"/>DATASETS</h5>
                        </Grid>
                        <Grid item sm={6} md={6} lg={6} className="col-1">
                           <h2>ALL</h2>
                           <span className="cat-reference">&nbsp;</span>
                        </Grid>
                        <Grid item sm={6} md={6} lg={6} className="col-2">
                           <div className="pods-icon-wrap">
                              <img src={AllIcon} alt="dataset chart icon"/>
                              <span className="tooltiptext4">{countData.allCount} <br className="break"/> Datasets</span>
                           </div>
                        </Grid>
                     </Grid>
                     </Link>
                  </Grid>

                </Grid>
            </Grid>

          </Grid>
				
        </div>
        
        <Footer />
      </Container>
    </Suspense>
  );
}