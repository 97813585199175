import React, {useState,useContext} from 'react';
import { Container } from '@mui/material';
import { useParams  } from "react-router-dom";
import Typography from '@mui/material/Typography';
import '../../main.css'
import LoadingSpinner from '../../Components/loader/spinner';
import CatData from "../../json/catalogdata.json";
const Brand = React.lazy(()=> import('../../Components/header/appheader'))
const Footer = React.lazy(()=> import('../../Components/footer/'))

export default function ChartPagePage() {
    
    const [pData, setpData]= useState(null)
    const {itemid} = useParams();
    const fetchId = async () => {
          const data = CatData;
          let filterData= data && data.filter((item)=>item._id.$oid==itemid);
            if(filterData){
                setpData(filterData[0]);
            }else{
                setpData([]);
            }

      };
    React.useEffect(()=>{
      fetchId();
    }, [])
    return (
        <Container className='udp-hp-wrapper bg-grey' maxWidth='false'> 
            <Brand />  
            {pData==null && ( 
              <div className='page-body'>
                  <div className='text-center' style={{ height: '300px', paddingTop: '150px' }}>
                        <LoadingSpinner />
                    </div>
              </div>
            )} 
            {pData!=null && (
                <div className='page-body'>
                <Typography variant='h5'>{pData.TableName}</Typography>
                <div className='embedLoader-iframe'  dangerouslySetInnerHTML={{__html: pData.VizEmbedcode}}></div>
                </div>
            )}
            
            <Footer />
        </Container>
    )
}