import React, { Suspense } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import { useLocation, Link } from 'react-router-dom';
import './macroIndicators.css'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { useParams  } from "react-router-dom";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import MetaD from "../../json/MetaData.json";
import ProductD from "../../json/ProductsData.json";
import "../../css/userStyle.css";
import SwaggerUserCPI from '../../Components/swaggerUserCPI';
import SwaggerUserIIP from '../../Components/swaggerUserIIP';
import SwaggerUserASI from '../../Components/swaggerUserASI';
import SwaggerUserNAS from '../../Components/swaggerUserNAS';
import SwaggerUserPLFS from '../../Components/swaggerUserPLFS';
import LoadingSpinner from '../../Components/loader/spinner';
import externelLink from '../../assets/external-link.png';
const Brand = React.lazy(() => import('../../Components/header/appheader'))
const Footer = React.lazy(()=> import('../../Components/footer/'))

export default function MacroPage() {
  const [value, setValue] = React.useState('1');
  const [pageData,setPageData] = React.useState(null);
  const [ProductData,setProductData] = React.useState(null);
  const [MetaData,setMetaData]= React.useState(null);
  const [metaJson,setMetaJson] = React.useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  const fetchMetaData = async () => {

      const data = MetaD;
      const metaJsonVal = data.filter((item)=>item.Product==productname.toUpperCase());
      setMetaJson(metaJsonVal);
   
  };
  const fetchProductData = async () => {
    
      const data2 = ProductD;
      let filterData= data2[0].products.filter((item)=>item.product==productname.toUpperCase());
      setPageData(filterData);
   
  };

const url = useLocation();
const queryParams = new URLSearchParams(url.search);
const productname = queryParams.get('product');

  React.useEffect(()=>{
    fetchMetaData();
    fetchProductData();
  }, [])


   return (
  <Suspense fallback={<div className='text-center loadspinner'><LoadingSpinner /></div>} className='bg-grey'>
       <Container className='udp-hp-wrapper bg-grey' maxWidth='false'> 
            <Brand />  
            {Array.isArray(pageData) && pageData.map((item,index)=>{

              return(
                <div className='innerpage-body fullwidth' key={index}>
                    <Typography variant='h5' className="macro-title" sx={{mb: '20px'}}>{item.title} ({item.product})</Typography>
                    <TabContext value={value}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" className="tablist">
                          {item.table && <Tab label="Table" value="1" />}
                          {item.viz && <Tab label="chart" value="2" />}
                          {item.swagger && <Tab label="API" value="3" />}
                          <Tab label="Metadata" value="4" />
                        </TabList>
                      </Box>
                      
                          <div>
                            <TabPanel value="1" className="tabpanel">
                              <div className='embedLoader-iframe macro-embed'>
                                <iframe src={item.table} width="100%" frameBorder="0" ></iframe>
                              </div>
                            </TabPanel>
                            <TabPanel value="2" className="tabpanel">
                              <div className='embedLoader-iframe macro-embed'>
                                <iframe src={item.viz} width="100%" frameBorder="0" ></iframe>
                              </div>
                            </TabPanel>
                            <TabPanel value="3" className="tabpanel">
                              
                              <div className='embedLoader-iframe'>
                              {item.id === 'cpi' && <SwaggerUserCPI />}
                              {item.id === 'iip' && <SwaggerUserIIP />}
                              {item.id === 'asi' && <SwaggerUserASI />}
                              {item.id === 'nas' && <SwaggerUserNAS />}
                              {item.id === 'plfs' && <SwaggerUserPLFS />}
                              
                              </div>
                               
                            </TabPanel>
                            <TabPanel value="4" className="tabpanel">
                              <div className='embedLoader-iframe'>
                                {metaJson.length>0 && metaJson.map((item,index)=>(
                                    <ul className="gridtable" key={index}>
                                    <li><strong>Product:</strong> {item.title}</li>
                                    <li><strong>Category:</strong> {item.Category}</li>
                                    <li><strong>Geography:</strong> {item.Geography}</li>
                                    <li><strong>Frequency:</strong> {item.Frequency}</li>
                                    <li><strong>Time Period:</strong> {item.TimePeriod}</li>
                                    <li><strong>Data Source:</strong> {item.DataSource}</li>
                                    <li><strong>Description:</strong> {item.Description}</li>
                                    <li><strong>Last Updated Date:</strong> {item.lastUpdateDate}</li>
                                    <li><strong>Future Release:</strong> {item.FutureRelease}</li>
                                    <li><strong>Base Period:</strong> {item.BasePeriod}</li>
                                    <li><strong>Key Statistics:</strong> {item.Keystatistics}</li>
                                    {
                                      item.Note && (<li><strong>Note:</strong> {item.Note}</li>)
                                    }
                                    {item.remarks  && 
                                      (<li><strong>Remarks:</strong> {item.remarks}</li>)}
                                    <li><strong>National Metadata Structure:</strong> <Link to={item.nmdslink} target="_blank" className="nmdslink">{item.NMDS} <svg x="0" y="0" viewBox="0 0 515.283 515.283" ><g><path d="M372.149 515.283H85.881c-22.941 0-44.507-8.934-60.727-25.155S.001 452.34.001 429.402V143.134c0-22.94 8.934-44.506 25.154-60.726s37.786-25.154 60.727-25.154h114.507c15.811 0 28.627 12.816 28.627 28.627s-12.816 28.627-28.627 28.627H85.881c-7.647 0-14.835 2.978-20.241 8.384s-8.385 12.595-8.385 20.242v286.268c0 7.647 2.978 14.835 8.385 20.243 5.406 5.405 12.594 8.384 20.241 8.384h286.267c7.647 0 14.835-2.978 20.242-8.386 5.406-5.406 8.384-12.595 8.384-20.242V314.895c0-15.811 12.817-28.626 28.628-28.626s28.628 12.816 28.628 28.626v114.507c0 22.94-8.934 44.505-25.155 60.727-16.221 16.22-37.788 25.154-60.726 25.154zm-171.76-171.762c-7.327 0-14.653-2.794-20.242-8.384-11.179-11.179-11.179-29.306 0-40.485L417.544 57.254H314.896c-15.811 0-28.626-12.816-28.626-28.627S299.085 0 314.896 0h171.761a28.542 28.542 0 0 1 19.997 8.144l.002.002.056.056.017.016.044.044.029.029.032.032.062.062.062.062.031.032.029.029a.62.62 0 0 1 .06.061l.056.057.002.002a28.55 28.55 0 0 1 8.144 19.998v171.761c0 15.811-12.817 28.627-28.628 28.627s-28.626-12.816-28.626-28.627V97.739l-237.4 237.399c-5.585 5.59-12.911 8.383-20.237 8.383z" fill="inherit" opacity="1" data-original="#000000"></path></g></svg> </Link></li>
                                    </ul>
                                ))}
                                  
                              </div>
                            </TabPanel>
                            
                          </div>
                      
                    </TabContext>
                </div>
             )
            })}
            <Footer />
        </Container>
     </Suspense>
   );
 }