import React from "react";
import LoaderImg from "../../assets/newloader.gif";
import "./spinner.css";


export default function LoadingSpinner({varient}) {
  return (
    <div className="spinner-container">
      <img src={LoaderImg} />
    </div>
  );
}