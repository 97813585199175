import React, { useState,Suspense } from "react";
import { Button, Container, Grid, Typography, Autocomplete, InputAdornment, TextField } from "@mui/material";
import "./homepage.css";
import SearchIcon from '../../assets/search.png';
import { Link } from "react-router-dom";
import RightArrowImage from "../../assets/right-arrow.png";
import Popper from '@mui/material/Popper';
import podData from "../../json/ProductsData.json";
import LoadingSpinner from '../../Components/loader/spinner';

const Brand = React.lazy(() => import("../../Components/header/appheader"));
const Footer = React.lazy(() => import("../../Components/footer/"));
const Slider = React.lazy(() => import("../../Components/ui/slider"));


export default function HomePage() {
  const [searchValue, setSearchValue] = useState('');
  const [productData, setProductData] = useState(null); // Initialize state for product data
  const [options, setOptions] = useState([]);
  const [optionUrl,setOptionUrl] = useState('');
  let baseUrl = process.env.REACT_APP_SERVER_URL;
  let endpoint = '/accessLogs';
  const apiUrl = `${baseUrl}${endpoint}`.replace(/'/g, '');
  const insertAccessLogs = async () => {
    try {
        const response = await fetch(`${apiUrl}`);
        
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();

    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
    }
};
  const fetchProductData = async () => {
    const data = podData;
    setProductData(data);

    const options = data[0].products.map((product) => ({
      label: `${product.title} (${product.product})`,
      url: product.url,
      id:product.id
    }));
    setOptions(options);
  };

  const handleSearchChange = (event, newValue) => {
    // newValue contains the selected value from your search input
  
    // Assuming setSearchValue is a state setter function to update searchValue state
    setSearchValue(newValue.label);
  
    // Determine the type of newValue
    if (typeof newValue === 'string') {
      // newValue is a string
      if (newValue.length < 3) {
        console.log("Type at least 3 letters");
        return; // Exit early if the input is less than 3 characters
      }
  
      const filterData = options.filter(item => item.label.toLowerCase().includes(newValue.toLowerCase()));
  
      if (filterData.length === 0) {
        console.log("No data found");
      } else {
        const element = document.getElementById(filterData[0].id);
        if (element) {
          element.click(); // Click the first found element if it exists
        }
      }
    } else if (typeof newValue === 'object' && newValue !== null) {
      // newValue is an object
      let textVal = newValue.label;
  
      if (textVal.length < 3) {
        console.log("Type at least 3 letters");
        return; // Exit early if the input is less than 3 characters
      }
  
      // Handle parentheses extraction if needed
      if (textVal.includes("(")) {
        let finalVal = textVal.split('(');
        let lastVal = finalVal[1].split(')');
        textVal = lastVal[0].trim(); // Extract the text between parentheses and trim any leading/trailing spaces
      }
  
      const filterData = options.filter(item => item.label.toLowerCase().includes(textVal.toLowerCase()));
  
      if (filterData.length === 0) {
        console.log("No data found");
      } else {
        const element = document.getElementById(filterData[0].id);
        if (element) {
          element.click(); // Click the first found element if it exists
        }
      }
    } else {
      console.log("Invalid newValue type");
    }
  };
  

 

  React.useEffect(() => {
    fetchProductData();
    insertAccessLogs();
  }, []);
  console.log(options)
  
  return (
    <Suspense fallback={ <div className='text-center loadspinner'><LoadingSpinner /></div>}>
      <Container className="udp-hp-wrapper" maxWidth="false">
        <Brand />
        <div className="hp-body body-home">

          <Grid container spacing={4} className="section-1 search-wrap">
          <Grid item className="search-box main-search" sm={12} md={12} lg={12}>
          <Grid item className="search-content" sm={12} md={12} lg={9}>
              <Typography variant="h5" mb={3}>
              Welcome to the e- Sankhyiki Portal for India's Official  Statistics, your definitive source for comprehensive and reliable statistical data.
              </Typography>
              </Grid>
              <Grid item className="search-content" sm={12} md={12} lg={6}>
              <Autocomplete
              lg={8}
              size="small"
              className='main-searchbox'
                freeSolo
                id="home-search"
               onChange={handleSearchChange}
                disableClearable
                getOptionLabel={(option) => typeof option === 'string' ? option : option.label}
                options={options}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Link to={option.url} target="_blank" id={option.id} className="list-link">{option.label}</Link>
                  </li>
                )}
                PopperComponent={(props) => (
                  <Popper {...props} className="custom-popper">
                    {props.children}
                  </Popper>
                )}
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: "none !important",
                    },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search Product ( Example: Consumer Price Index etc. )"
                    InputProps={{
                      ...params.InputProps, startAdornment: (<InputAdornment position="end"><img src={SearchIcon} alt="right-arrow" className="search-icon" sx={{ml:0}}/></InputAdornment>) 
                    }}

                  />
                )}
              />
              </Grid>
            </Grid>
          </Grid>

          <Grid className="section-2">
            <Slider className="slider" slider_data={productData} />
          </Grid>

          <Grid container className="section-3">
           <Grid item container className="inner-1" sm={12} md={6} lg={6}>
            <div className="inner1-content">
             <Grid item className="text-wrap" sm={12} md={12} lg={12}>
               <Typography variant="h5">Data Catalogue</Typography>
                <p>
                A data catalog is a detailed inventory of all data assets in an organization, designed to help data professionals quickly find the most appropriate data for any analytical or business purpose.*
                </p>
                <p>
                It is a single point of access to all publicly available datasets of the ministry designed to make the datasets easy to find, download and share. At present it is a repository of more than 2500 datasets.               
                </p>
                <div className="catlogbtn-wrap">
                  <Link to="/catalogue-main" title="Click for more detail">
                      <Button color="warning" variant="outlined" className="btn-search">Data Catalogue <img src={RightArrowImage} alt="right-arrow" className="btn-icon"/></Button>
                  </Link>
                  <div className="reference"><i className="astrik">*</i>  <span>www.ibm.com/topics/data-catalog</span></div>
               </div>
             </Grid>
             
             </div>
           </Grid>
           <Grid item container className="inner-2" sm={12} md={6} lg={6}>
           <div className="inner2-content">

            <Grid item className="text-wrap" sm={12} md={12} lg={12}>
            <Typography variant="h5">Macro Indicators</Typography>
                <p>Macro Indicator section  is dedicated to providing access to a wide range of indicators published by  National Statistical Office of India. Whether you're a researcher, policymaker, student, or simply a curious citizen, this portal offers an unparalleled gateway to explore India's official data.
                </p><p> 
                Delve into the wealth of information available, empowering you with the insights needed to understand and analyze the diverse aspects of India's development and progress.
                </p>
               <Link to="/macroindicators-main" title="Click for more detail">
                  <Button color="warning" variant="outlined" className="btn-search">Macro Indicators <img src={RightArrowImage} alt="right-arrow" className="btn-icon"/></Button>
							 </Link>
					
            </Grid>
            </div>
           </Grid>
          </Grid>

        </div>

        <Footer />
      </Container>
    </Suspense>
  );
}
